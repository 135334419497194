import classes from './Advert.module.css';

const Advert = () => {
  return (
    <div className={classes.ad}>
      <h1>www.jahbyte.com</h1>
    </div>
  );
};

export default Advert;
